<template>
  <div>
    <Loading v-if="displayLoading" />
    <template v-else>
      <div class="page">
        <div
          v-if="getModuleConfig('terms_page.step_section.enable_step_section')"
          class="p-3"
        >
          <SharedStep
            :steps="getModuleConfig('terms_page.step_section.steps')"
          />
        </div>

        <div
          v-if="getModuleConfig('terms_page.form_title')"
          class="page__title"
        >
          {{ getModuleConfig("terms_page.form_title") }}
        </div>
        <div v-if="getModuleConfig('terms_page.form_desc')" class="page__desc">
          {{ getModuleConfig("terms_page.form_desc") }}
        </div>

        <div
          v-for="(term, index) in terms"
          :key="index"
          :class="index == 0 ? 's-mb-8' : ''"
        >
          <Accordion :title="term.title">
            <div v-html="term.text"></div>
          </Accordion>
          <Checkbox v-model="checked[index]" :name="'checkbox' + index">{{
            term.checkbox_text
          }}</Checkbox>
        </div>
      </div>

      <div class="page__button">
        <Button
          class="s-btn-bg-primary"
          :disabled="!isAllChecked()"
          @click="goNextPage"
        >
          {{ getModuleConfig("terms_page.button_text") }}
        </Button>
      </div>
    </template>
  </div>
</template>

<script>
import Checkbox from "@/components/Page/Liff/Shared/Checkbox.vue";
import Accordion from "@/components/Page/Liff/Shared/Accordion.vue";
import Button from "@/components/Page/Liff/Shared/Button.vue";
import workerMixin from "@/mixins/liff/worker";
import Loading from "@/components/Page/Liff/Shared/Loading";
import SharedStep from "@/components/Page/Liff/Shared/Steps";

export default {
  mixins: [workerMixin],
  components: {
    Checkbox,
    Accordion,
    Button,
    Loading,
    SharedStep,
  },
  data() {
    return {
      isCheckedRegister: false,
      checked1: false,
      checked2: false,
      terms: [],
      checked: [],
      displayLoading: true,
    };
  },
  created() {
    this.init();
  },
  beforeMount() {
    this.initTerms();
  },
  methods: {
    async init() {
      let result = await this.checkRegistered();

      if (result === false) {
        return;
      }
      if (!(this.getModuleConfig("terms_page.enable") ?? false)) {
        this.goNextPage();
      } else {
        this.displayLoading = false;
        this.isCheckedRegister = true;
      }
    },
    async initTerms() {
      let terms = this.getModuleConfig("terms_page.contents") ?? [];
      terms = terms.sort((a, b) => a.order - b.order);

      let checked = [];

      for (let i = 0; i < terms.length; i++) {
        checked[i] = false;
      }

      this.terms = terms;
      this.checked = checked;
    },
    isAllChecked() {
      return this.checked.every((c) => c === true);
    },
    goNextPage() {
      this.$router.push({
        name: "LiffWorkerActive",
        params: {
          orgCode: this.$route.params.orgCode,
        },
        query: this.$route.query,
      });
    },
  },
};
</script>
