<template>
  <div>
    <div class="page">
      <div
        v-if="getModuleConfig('failed_page.form_title')"
        class="page__title"
      >
        {{ getModuleConfig("failed_page.form_title") }}
      </div>
      <div v-if="getModuleConfig('failed_page.form_desc')" class="page__desc">
        {{ getModuleConfig("failed_page.form_desc") }}
      </div>
      <div
        v-if="getModuleConfig('failed_page.content')"
        v-html="getModuleConfig('failed_page.content')"
      ></div>
    </div>

    <div class="page__button s-space-y-4">
      <MemberButton
        :button-text="getModuleConfig('failed_page.button_text')"
        :button-url="getModuleConfig('failed_page.button_url')"
        :type="getModuleConfig('failed_page.button_action')"
      >
      </MemberButton>
    </div>
  </div>
</template>

<script>
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
import workerMixin from "@/mixins/liff/worker";
import { mapGetters } from "vuex";
import liff from "@line/liff";

export default {
  mixins: [workerMixin],
  components: {
    MemberButton,
  },

  data() {
    return {
      showLoading: false,
      showInfos: {},
      workerInfo: {
      },
    };
  },

  async mounted() {
    await this.init();
    await this.sendLiffMessage();
  },

  computed: {
    ...mapGetters("liffGeneral", ["liffId"]),
    getAvatar() {
      return this.workerInfo && this.workerInfo.avatar_url;
    },
  },

  methods: {
    async sendLiffMessage() {
      if (this.getModuleConfig("failed_page.send_messages.enable")) {
        await liff
          .sendMessages([
            {
              type: "text",
              text: this.getModuleConfig("failed_page.send_messages.text"),
            },
          ])
          .then(() => {
            console.log("message sent");
          })
          .catch((err) => {
            console.log("error", err);
          });
        return;
      }
    },
    async init() {
      await liff
        .init({
          liffId: this.liffId,
        })
        .then(() => {
          if (!liff.isLoggedIn()) {
            liff.login({
              redirectUri: window.location.href,
            });
          }
        })
        .catch(() => {
          this.liffFailed();
        });
    },
    getWorkerInfo(code) {
      return this.workerInfo[code] || null;
    },
  },
};
</script>
